

















































































































































































































import { formatFileSize } from '@simpl/base-management/utils/file'
import mixins from 'vue-typed-mixins'
import StorageQuota from '../../mixins/apollo/StorageQuota'
import { MUTATIONS } from '@simpl/auth/store/consts'

let id = 0

export default mixins(StorageQuota).extend({
  name: 'FileUploader',

  props: {
    accept: {
      type: String,
      default: '*'
    },

    aspectRatio: Number,
    minWidth: [String, Number],
    maxWidth: [String, Number],
    width: [String, Number],
    minHeight: [String, Number],
    maxHeight: [String, Number],
    height: [String, Number],

    dense: Boolean,

    id: String,
    isUploading: Boolean,
    uploadProgress: Number,

    initialFile: Object,
    oldFileDetails: Object,

    canCancel: Boolean,
    canReset: Boolean,

    verificationErrors: Array
  },

  data () {
    return {
      file: this.initialFile || null,
      inputId: this.id,
      dragOver: 0,
      errors: [] as any[],
      showCheckFile: false,
      checkFileTimeoutId: null! as ReturnType<typeof setTimeout>
    }
  },

  computed: {
    acceptedFiletypes (): string {
      switch (this.accept) {
        case 'video/*,audio/*':
          return 'Videos/Audios (*.mp4, *.mp3)'
        case '.zip':
          return 'ZIP (*.zip)'
        case '.pdf':
          return 'PDF (*.pdf, 200 MB)'
      }
      return '*.*'
    }
  },

  watch: {
    verificationErrors (v: []) {
      this.errors = (v && v.slice()) || []
    },
    initialFile (v: string) {
      if (!this.file) {
        this.file = v
      }
    },
    uploadProgress (v: number) {
      if (v >= 1 && this.isUploading) {
        this.checkFileTimeoutId = setTimeout(() => {
          this.showCheckFile = this.isUploading
        }, 2000)
      }
    }
  },

  created () {
    if (!this.id) {
      id += 1
      this.inputId = `file_${id}`
    }
  },
  beforeDestroy () {
    clearTimeout(this.checkFileTimeoutId)
  },

  methods: {
    formatFileSize,

    onInput ($event: Event) {
      this.errors = []
      const target = ($event.target as any)
      this.file = target.files[0]
      this.validateFileSize() && this.$emit('update', this.file)
      target.value = ''
    },

    /** @type {DragEvent} $e */
    onDrop ($event: DragEvent) {
      this.errors = []
      this.dragOver = 0
      this.file = $event!.dataTransfer!.files[0]
      this.validateFileSize() && this.$emit('update', this.file)

      $event.preventDefault()
    },

    validateFileSize (): boolean {
      if (this.storageQuota.quota !== -1 && this.file.size / 1024 > this.storageQuota.available) {
        this.errors.push({
          text: 'plans.storage.notEnoughStorageQuota',
          vars: { } as any,
          showMore: true
        } as any)
        this.$emit('error')
        return false
      } else if (this.accept === '.pdf' && this.file.type === 'application/pdf' && this.file.size > 200 * 1024 * 1014) {
        this.errors.push({
          text: 'validation.max.numeric',
          vars: { attribute: 'PDF', max: '200 MB' } as any
        } as any)
        this.$emit('error')
        return false
      }
      return true
    },

    emitSave () {

    },

    onCancel () {
      this.$emit('cancel')
    },

    reset () {
      this.file = null
      this.$emit('update', this.file)
    }
  }
})

